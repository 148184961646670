import * as React from "react";
import * as PropTypes from "prop-types";
import {getPath} from "../../../../../../../../lib/utils/";
import {isEnvironmentAdmin} from "../../../../../../../../lib/user-environment-permissions-helpers";
import {Checkbox, Chip, IconButton, Menu, ListItem, MenuItem} from "@material-ui/core";
import {Create, Delete, Edit, InsertChart, MoreVert, Search, Update} from "@material-ui/icons";
import "./style.less";

const Component: React.FunctionComponent<any> = props => {
    const actAsAdmin = isEnvironmentAdmin(props.login, props.location, props.config);

    const isIknowMedR4External = props.state.selectedDataSourceType === "DataSourceIKnowMedR4External";

    const selectedResources = getPath(props, "state.selectedResources") || {};
    const anyChecked = Object.keys(props.state.selectedResources).reduce((acc, key) => {
        if (props.state.selectedResources[key]) return ++acc; else return acc;
    }, 0);

    const type = getPath(props, "resource.type");
    const isChecked = !!selectedResources[type];

    const sources = getPath(props, "state.sources") || [];
    let source;
    let selectedSources = {
        read: "",
        search: "",
        "search-type": "",
        "search-system": "",
        create: "",
        update: "",
        delete: ""
    };
    let sourceTag = <Chip className="chip" style={{background: "rgba(0, 0, 0, 0.1)"}} label={<em style={{lineHeight: "32px"}}>No Default Data Adapter</em>}/>;
    const overriddenBy = getPath(props, "resource.overriddenBy");
    const defaultSourceId = getPath(props, "state.data.configuration.defaultDataSource");
    const defaultSource = sources.find((it) => it.id === defaultSourceId) || {};
    if (isIknowMedR4External && getPath(props, "resource.gatewayResourceOperations")) {
        let sourceNames = [];
        ["read", "search", "create", "update", "delete"].forEach((it) => {
            const operationSource = sources.find((src) => src.id === props.resource.gatewayResourceOperations[it]?.overriddenBy) || {};
            selectedSources[it] = operationSource.id
            if (it === "search") {
                selectedSources["search-type"] = operationSource.id;
                selectedSources["search-system"] = operationSource.id;
            }
            const sourceName = operationSource.name === defaultSource.name ? `${operationSource.name} (default)` : operationSource.name || "";
            if (sourceName && sourceNames.indexOf(sourceName) < 0) sourceNames.push(sourceName);
        })
        if (sourceNames.length) {
            let sourceName = sourceNames.join(", ");
            if (sourceName.length > 58) sourceName = sourceName.substring(0, 55) + "...";
            sourceTag = <Chip className="chip" style={{background: props.muiTheme.palette.primary1Color, lineHeight: "32px"}}
                label={<span style={{color: "#fff"}}>
                    {sourceName}
                </span>}/>;
        }
    } else if (overriddenBy) {
        source = sources.find((it) => it.id === overriddenBy) || {};
        let sourceName = source.name || "";
        if (sourceName) {
            if (sourceName.length > 60) sourceName = sourceName.substring(0, 55) + "...";
            sourceTag = <Chip className="chip" style={{background: props.muiTheme.palette.primary1Color, lineHeight: "32px"}}
                label={<span style={{color: "#fff"}}>
                    {sourceName}
                </span>}/>;
        }
    } else {
        let defaultSourceName = defaultSource.name || defaultSourceId || "";
        if (defaultSourceName) {
            if (defaultSourceName.length > 60) defaultSourceName = defaultSourceName.substring(0, 50) + "...";
            sourceTag = <Chip className="chip" style={{background: props.muiTheme.palette.primary3Color}}
                label={<span style={{color: props.muiTheme.palette.textColor, lineHeight: "32px"}}>
                    {defaultSourceName + " (default)"}
                </span>}/>;
        }
    }

    const leftIcon = <Checkbox checked={isChecked} className="left-icon" color="primary"
        onClick={event => {
            event.stopPropagation();
            props.onSetState({
                selectedResources: {
                    ...selectedResources,
                    [type]: !selectedResources[type]
                }
            });
        }}/>;

    const rightIcon = <React.Fragment>
        <IconButton className={`right-icon ${anyChecked ? "any-checked" : ""}`} onClick={e => props.onSetState({menuOpen: type, anchorEl: e.currentTarget})}>
            <MoreVert/>
        </IconButton>
        <Menu open={props.state.menuOpen === type}  onClose={() => props.onSetState({menuOpen: false})} anchorEl={props.state.anchorEl}>
            <MenuItem onClick={event => {
                event.stopPropagation();
                props.onSetState({
                    selectedResources: {
                        [type]: !selectedResources[type]
                    },
                    source,
                    selectedSources,
                    dialog: ":EDIT:"
                });
            }}>
                <Edit style={{color: "rgba(0, 0, 0, .5)", marginRight: "16px"}}/> Edit
            </MenuItem>
        </Menu>
    </React.Fragment>;

    return <ListItem disabled className={`gw-mapping-rl-item ${isChecked ? "is-checked" : ""}`} style={{opacity: 1}}>
        {actAsAdmin ? leftIcon : null}
        <div className="content">
            <div className="type">
                {type}
            </div>

            <div className="operations">
                <InsertChart style={{color: props.resource.read ? props.muiTheme.palette.primary1Color : "rgba(0, 0, 0, 0.1)"}} className="operation-icon"/>
                <Search style={{color: props.resource.search ? props.muiTheme.palette.primary1Color : "rgba(0, 0, 0, 0.1)"}} className="operation-icon"/>
                <Create style={{color: props.resource.create ? props.muiTheme.palette.primary1Color : "rgba(0, 0, 0, 0.1)"}} className="operation-icon"/>
                <Update style={{color: props.resource.update ? props.muiTheme.palette.primary1Color : "rgba(0, 0, 0, 0.1)"}} className="operation-icon"/>
                <Delete style={{color: props.resource.delete ? props.muiTheme.palette.primary1Color : "rgba(0, 0, 0, 0.1)"}} className="operation-icon"/>
            </div>

            <div className="source">
                {sourceTag}
            </div>
        </div>
        {actAsAdmin ? rightIcon : null}
    </ListItem>;
}

Component.propTypes = {
    config: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    login: PropTypes.object.isRequired,
    muiTheme: PropTypes.object.isRequired,
    resource: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    onSetState: PropTypes.func.isRequired
};

export default Component;
